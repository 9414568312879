body {
  font-family: "Inter", sans-serif;
}

img {
  width: 100%;
}

.header-logo-text {
  text-transform: uppercase;
  font-weight: bold;
}

.App {
  overflow: hidden;
}

.container {
  max-width: 90%;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  // height: 100px;
  padding: 10px 0;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 15px 0;
    @media only screen and (max-width: 900px) {
      flex-direction: column;
    }
  }
  &-logo {
    display: flex;
    align-items: center;
    gap: 20px;

    &-text {
      max-width: 300px;
      margin-left: 80px;
      @media only screen and (max-width: 900px) {
        margin-left: 0;
      }
    }
  }
  &-menu {
    display: flex;
    gap: 30px;
    @media only screen and (max-width: 900px) {
      gap: 15px;
    }
  }
}

.main {
  position: relative;
  z-index: 1;
  background-color: #000;
  background-image: url("./assets/bg.png");
  background-size: cover;
  padding: 100px 50px 50px;
  @media only screen and (max-width: 900px) {
    padding: 50px 20px;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    gap: 100px;
    @media only screen and (max-width: 900px) {
      gap: 50px;
      margin-top: 30px;
      justify-content: center;
    }
  }
  &-post {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 100px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    // padding: 20px;
    // border: 1px solid #000;
    &-unicorn {
      display: flex;
      justify-content: space-between;
      img {
        width: 180px;
        @media only screen and (max-width: 900px) {
          width: 100px;
        }
      }
      &-1 {
        animation: move3 6s ease infinite;
      }
      &-2 {
        animation: move2 6s ease infinite;
      }
    }
  }
  &-logo-img {
    width: 100px;
    position: absolute;
    top: -30px;
    left: 50px;
    // margin-top: -100px;
    @media only screen and (max-width: 900px) {
      top: -20px;
      width: 80px;
    }
  }
  .tw-post {
    max-width: 650px;
    padding: 20px;
    border-radius: 12px;
    border: 2px solid #000;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    &-pos {
      position: absolute;
      width: 300px;
      bottom: 20px;
      right: 20px;
      z-index: 999;
      @media only screen and (max-width: 900px) {
        width: 150px;
      }
    }
  }
  .cloud-1 {
    max-width: 400px;
  }
  .cloud-2 {
    max-width: 350px;
  }
  .cloud-3 {
    margin-top: 100px;
    max-width: 270px;
    @media only screen and (max-width: 900px) {
      max-width: 200px;
    }
  }

  &-footer {
    padding: 100px 0 50px;
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    gap: 200px;
    @media only screen and (max-width: 900px) {
      flex-direction: column;
      align-items: center;
      gap: 50px;
    }
    &-left {
      max-width: 400px;
      @media only screen and (max-width: 900px) {
        max-width: 200px;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      gap: 50px;
      // align-items: flex-end;
      &-cloud {
        display: flex;
        max-width: 300px;
        // align-items: flex-end;
        align-self: flex-end;
        animation: move 10s ease infinite;

        @media only screen and (max-width: 900px) {
          max-width: 200px;
        }
      }
      p {
        // color: #fff;
        max-width: 550px;
        @media only screen and (max-width: 900px) {
          text-align: center;
        }
      }
    }
  }
}

.footer {
  background-color: #cccccc;
  padding: 30px 0;

  .container {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 900px) {
      flex-direction: column-reverse;
      align-items: center;
      font-size: 14px;
      gap: 20px;
    }
  }
  span {
    align-self: flex-end;
    @media only screen and (max-width: 900px) {
      display: block;
      text-align: center;
    }
  }
  &-socials {
    display: flex;
    gap: 30px;
    @media only screen and (max-width: 900px) {
      gap: 20px;
    }
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      img {
        width: 60px;
        @media only screen and (max-width: 900px) {
          width: 40px;
        }
      }
    }
  }
}

.cloud-1 {
  animation: move 6s ease infinite;
}
.cloud-2 {
  animation: move 8s ease infinite;
}
.cloud-3 {
  animation: move 10s ease infinite;
}

@keyframes move {
  50% {
    transform: translateY(-25px);
  }
}

@keyframes move2 {
  50% {
    transform: translateX(-35px);
  }
}

@keyframes move3 {
  50% {
    transform: translateX(35px);
  }
}

.ca {
  margin-top: 100px;
  text-align: center;
  max-width: 100%;
  width: 100%;
  word-wrap: break-word;
  // color: #fff;
}

.chart {
  display: flex;
  justify-content: center;
  .chart-if {
    width: 60%;
    height: 600px;
  }
}

@media only screen and (max-width: 900px) {
  .chart {
    .chart-if {
      width: 100%;
      height: 400px;
    }
  }
}
